import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import Footer from "./widgets/Footer";
import iconStepOne from "../img/icon-step1.svg";
import iconStepTwo from "../img/icon-step2.svg";
import mixpanel from "mixpanel-browser";
import { AB_VERSION } from "../constants";

const Test = () => {
  const navigate = useNavigate();
  const clickDownload = () => {
    navigate("/journeys");
  };

  useEffect(() => {
    mixpanel.track(`$pageview-sat:feedback-finished`, {
      page: `feedback-finished`,
      abVersion: AB_VERSION,
    });
  }, []);

  return (
    <div className="container">
      <div className="flex flex-col items-center align-middle justify-center gap-12 mt-20 mx-2">
        <div className="text-center">
          <h4 className="fs-24 fSb pb-3">You are a great friend!</h4>
          <h3 className="fs-22 w-5/6 m-auto">
            Want to see how you could improve your emotional intelligence too?
          </h3>
        </div>
        <div className="flex flex-col md:flex-row gap-15 mx-auto text-center">
          <div className="flex flex-col items-center gap-3 max-w-[262px]">
            <img src={iconStepOne} alt="" />
            <p className="fs-22 m-0">Step 1</p>
            <p className="text-lg text-[#212121]/60 w-5/6">
              Share feedback about your friend
            </p>
          </div>
          <div className="flex flex-col items-center gap-3 max-w-[262px]">
            <img src={iconStepTwo} alt="" />
            <p className="fs-22 m-0">Step 2</p>
            <p className="text-lg text-[#212121]/60">
              Learn how to improve your emotional intelligence
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center mt-5">
        <Button
          className="btn-lg w-2/5 m-auto mt-1 block"
          onClick={() => clickDownload()}
          style={{ height: "58px" }}
        >
          <span className="text-white">Sure!</span>
        </Button>

        <div className="m-auto">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Test;
